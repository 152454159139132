import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
};

class NavDrawer extends React.Component {
  state = {
    right: false
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  render() {
    const { classes } = this.props;

    function ListItemLink(props) {
      return <ListItem button component="a" {...props} />;
    }

    function ListItemNavLink(props){
      const { to } = props;
      return <ListItem button component={NavLink} to={to} {...props} />;
    }

    const sideList = (
      <div className={classes.list}>
        <List component="nav">
          {/* {['Blog', 'Partner with us', 'Our team'].map((text) => (
            <ListItem button key={text}>
              <ListItemText primary={text} />
            </ListItem> */}
            {/* <ListItem button on>
              <a href="https://blog.elegate.org/" target="_blank" className="navbar-item"><ListItemText>Blog</ListItemText></a>
            </ListItem> */}
            <ListItemNavLink to="/" className="navbar-item"><ListItemText primary="Home"></ListItemText>
            </ListItemNavLink>
            <ListItemLink href="https://blog.elegate.org/" target="_blank"><ListItemText primary="Blog"/></ListItemLink>
            <ListItemNavLink to="/form" className="navbar-item"><ListItemText primary="Partner with us"></ListItemText>
            </ListItemNavLink>
            {/* <ListItemLink href="#team-section">
              <ListItemText primary="Our team" />
            </ListItemLink> */}
          
        </List>
      </div>
    );

    return (
      <div>
        {/* <Button onClick={this.toggleDrawer('right', true)}>Open Right</Button> */}
        <span
          onClick={this.toggleDrawer("right", true)}
          className="navbar-toggle"
          id="js-navbar-toggle"
        >
          <MenuIcon />
        </span>
        <Drawer
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer("right", false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("right", false)}
            onKeyDown={this.toggleDrawer("right", false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

NavDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NavDrawer);
