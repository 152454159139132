import React, { Component } from 'react';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import { Paper, TextField, Button } from '../node_modules/@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import OrderSlider from './OrdersSlider';
import ElegatePalette from './ElegatePalette';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './PartnerForm.css';
import uuid from 'uuid';
import axios from 'axios';
// import { strings } from './components/language';
import { strings } from './components/languages';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#70142c' },
    secondary: { main: '#11cb5f' }
  },
  typography: { useNextVariants: true }
});

const styles = theme => ({
  formLabel: {
    fontSize: '12px',
    marginLeft: '10px',
    marginTop: '-3px',
    fontFamily: 'helveticaRoman'
  },

  checkboxRoot: {
    color: '#70142C',
    marginTop: '-5px',
    '&$checked': {
      color: '#70142C'
    }
  },
  checked: {},
  checkboxLabel: {
    fontFamily: 'helveticaRoman',
    fontSize: '14px',
    marginTop: '-5px',
    marginLeft: '-3px'
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

class PartnerForm extends React.Component {
  state = {
    checkedYesDelivery: false,
    loading: false,
    success: false,
    // checkedNoDelivery: false,
    name: '',
    phone: '',
    email: '',
    rest_name: '',
    rest_address: '',
    num_of_orders: 0,
    user_ref: uuid(),
    successMsg: ''
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSlider = value => {
    this.setState({ num_of_orders: value });
  };
  onChatClick = () => {
    if (!this.state.loading) {
      this.setState(
        {
          success: false,
          loading: true
        },
        () => {
          this.timer = setTimeout(() => {
            this.setState({
              loading: false,
              success: true
            });
          }, 2000);
        }
      );
    }
    const {
      user_ref,
      name,
      phone,
      email,
      rest_name,
      rest_address,
      checkedYesDelivery,
      num_of_orders
    } = this.state;

    const newConnection = {
      name,
      phone,
      email,
      rest_name,
      rest_address,
      checkedYesDelivery,
      num_of_orders
    };

    axios({
      method: 'post',
      url:
        'https://cors-anywhere.herokuapp.com/https://elegateform.herokuapp.com/sendmail',
      data: newConnection,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(res => {
        console.log(res.data.success);
        this.setState({ successMsg: res.data.success });
      })
      .catch(err => {
        console.log(err.message);
      });
    // ChatBot Stuff .. Happens Anyway .. whether msg sent OR not
    window.confirmOptIn(user_ref);
  };

  handleButtonClick = () => {};

  render() {
    const { classes } = this.props;
    // const { loading, success } = this.state;

    const {
      user_ref,
      name,
      phone,
      email,
      rest_name,
      rest_address,
      checkedYesDelivery,
      successMsg,
      loading,
      success
    } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success
    });
    return (
      <MuiThemeProvider theme={theme}>
        <Paper elevation={8} className='form-paper'>
          <div className='form-container'>
            <div className='form-title'>{strings.partner_with_us}</div>
            {successMsg !== '' ? (
              <p className='successMsg'>{successMsg}</p>
            ) : null}

            <div className='form-feilds-container'>
              <Grid container spacing={8}>
                <Grid item xs={7}>
                  {/* NAME FIELD */}
                  <TextField
                    id='outlined-dense'
                    label={strings.name}
                    name='name'
                    value={name}
                    onChange={this.onChange}
                    //placeholder="Full name"
                    InputLabelProps={{
                      className: classes.formLabel
                    }}
                    InputProps={{
                      // classes:{
                      //  input: classes.formLabel,
                      // },
                      disableUnderline: true
                    }}
                    className='form-feilds'
                    // margin="dense"
                    // variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}>
                  {/* PHONE FIELD */}
                  <TextField
                    id='outlined-dense'
                    label={strings.phone}
                    name='phone'
                    value={phone}
                    onChange={this.onChange}
                    InputLabelProps={{
                      className: classes.formLabel
                    }}
                    InputProps={{ disableUnderline: true }}
                    className='form-feilds'
                    // margin="dense"
                    // variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* EMAIL FIELD */}
              <TextField
                id='outlined-dense'
                label={strings.email}
                name='email'
                value={email}
                onChange={this.onChange}
                InputLabelProps={{
                  className: classes.formLabel
                }}
                InputProps={{ disableUnderline: true }}
                className='form-feilds'
                // margin="dense"
                // variant="outlined"
                fullWidth
              />
              {/* REST NAME FIELD */}
              <TextField
                id='outlined-dense'
                label={strings.restaurant_name}
                name='rest_name'
                value={rest_name}
                onChange={this.onChange}
                InputLabelProps={{
                  className: classes.formLabel
                }}
                InputProps={{ disableUnderline: true }}
                className='form-feilds'
                // margin="dense"
                // variant="outlined"
                fullWidth
              />
              {/* REST ADDRESS FIELD */}
              <TextField
                id='outlined-dense'
                label={strings.rest_address}
                name='rest_address'
                value={rest_address}
                onChange={this.onChange}
                InputLabelProps={{
                  className: classes.formLabel
                }}
                InputProps={{ disableUnderline: true }}
                className='form-feilds'
                // margin="dense"
                // variant="outlined"
                fullWidth
              />
              <div className='form-delivery'>
                <span>{strings.do_you_deliver}</span>

                <br />
                <FormControlLabel
                  classes={{
                    label: classes.checkboxLabel
                  }}
                  control={
                    <Checkbox
                      checked={this.state.checkedYesDelivery}
                      onChange={this.handleChange('checkedYesDelivery')}
                      value='checkedYesDelivery'
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checked
                      }}
                    />
                  }
                  label={strings.yes}
                />
              </div>
              <div className='form-ordersPweek'>
                <span>{strings.Your_current_orders}</span>
                <OrderSlider handleSlider={this.handleSlider} />
              </div>
              {/* Facebook Messenger Plugin */}
              <div
                className='fb-messenger-checkbox'
                origin='https://www.elegate.org/'
                page_id='460396614366150'
                messenger_app_id='2429520900657336'
                user_ref={user_ref}
                size='large'
                skin='light'
                center_align='true'
                allow_login='true'
              />
              <div className={classes.wrapper}>
                <Button
                  size='large'
                  variant='contained'
                  className={[buttonClassname, 'form-button']}
                  disabled={loading}
                  color='primary'
                  onClick={this.onChatClick}
                >
                  {success ? (
                    <div>
                      {' '}
                      {strings.yes}{' '}
                      <CheckIcon style={{ verticalAlign: 'bottom' }} />
                    </div>
                  ) : (
                    "Let's Chat!"
                  )}
                </Button>
                {loading && (
                  <CircularProgress
                    size={32}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </div>
          </div>
        </Paper>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(PartnerForm);
