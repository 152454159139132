import React, { Component } from 'react';
import Slider from 'react-rangeslider';
import PropTypes from 'prop-types';
import 'react-rangeslider/lib/index.css';
import './App.css';
import './slider.css';
import { strings } from './components/languages';

class OrderSlider extends Component {
  state = {
    orderCount: 5000,
    step: 10
  };

  handleChange = value => {
    this.setState({ orderCount: value });
    // Passing it up to its parent Component
    this.props.handleSlider(value);
  };
  render() {
    const { orderCount, step } = this.state;

    // const orderLabels = {
    //     10: 10,
    //     1000: 1000
    //   };
    return (
      <div>
        <Slider
          className='slider'
          min={0}
          max={5000}
          value={orderCount}
          orientation='horizontal'
          onChange={this.handleChange}
        />
        <div className='value-container'>
          <div className='value'>{orderCount} </div>
          <span className='value-unit'>{strings.orders_week}</span>
        </div>
      </div>
    );
  }
}

OrderSlider.propTypes = {
  handleSlider: PropTypes.func.isRequired
};

export default OrderSlider;
