import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import CountUp from 'react-countup';
import Slider from 'react-slick';
import VisibilitySensor from 'react-visibility-sensor';
import windowSize from 'react-window-size';
import { Icon, Button } from '../node_modules/@material-ui/core';
import './App.css';
import image from './image2.png';
import Navbar from './Navbar';
import SimpleSlider from './SimpleSlider';
import DemoPage from './DemoPage';
import { strings } from './components/languages';
import Switch from '@material-ui/core/Switch';

const styles = (theme) => ({
  formLabel: {
    fontSize: '12px',
    marginLeft: '10px',
    marginTop: '-3px',
    fontFamily: 'helveticaRoman',
  },

  checkboxRoot: {
    color: '#70142C',
    marginTop: '-5px',
    '&$checked': {
      color: '#70142C',
    },
  },
  checked: {},
  checkboxLabel: {
    fontFamily: 'helveticaRoman',
    fontSize: '14px',
    marginTop: '-5px',
    marginLeft: '-3px',
  },
});

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    // strings.setLanguage('ar');

    //   this.handleClick = this.handleClick.bind(this);
    // }

    // handleClick = (e) => {
    //   console.log("alloooo");
    //   e.preventDefault();

    //  window.location.href="https://www.google.com";
    //   console.log("alloooo2");
    // }
  }

  state = {
    isHidden: false,
    // mode: "mainPage",
    didViewCountUp: false,
    checkedYesDelivery: false,
    //checkedNoDelivery: false,

    language: 'en',
    checkedA: true,
  };

  // scroll () {
  //   if(this.componentDidMount)
  //   window.scrollTo(0, this.myRef.current.offsetTop);
  // }
  // openForm () {
  //   this.setState({mode:"partnerPage"});
  // }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  handleChange = (name) => (event) => {
    console.log('event.target.checked' + event.target.checked);
    if (event.target.checked) {
      this.setState({
        language: 'en',
      });
    } else {
      this.setState({
        language: 'ar',
      });
    }
    this.setState({
      [name]: event.target.checked,
    });
  };
  //   componentDidMount(){
  //     window.scrollTo(0, this.myRef.current.offsetTop)
  // }

  render() {
    const { classes } = this.props;
    strings.setLanguage(this.state.language);

    let carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 4,
    };
    if (this.props.windowWidth < 450) {
      carouselSettings.slidesToShow = 1;
      carouselSettings.slidesToScroll = 1;
    } else if (this.props.windowWidth < 580) {
      carouselSettings.slidesToShow = 2;
      carouselSettings.slidesToScroll = 2;
    } else if (this.props.windowWidth < 750) {
      carouselSettings.slidesToShow = 3;
      carouselSettings.slidesToScroll = 3;
    } else if (this.props.windowWidth < 1050) {
      carouselSettings.slidesToShow = 4;
      carouselSettings.slidesToScroll = 4;
    } else {
      carouselSettings.slidesToShow = 6;
      carouselSettings.slidesToScroll = 6;
    }

    return (
      <div>
        {/* <AppBar elevation={0} position="static" className="navbar">
            <Toolbar>
              <div className="navbar-logo">E l e g a t e</div>
              <div className="navbar-buttons">
                
              </div>
            </Toolbar>
          </AppBar> */}

        <Navbar />
        {/* <PartnerForm /> */}
        <div style={{ background: '#fba922' }}>
          {' '}
          <Switch
            right
            checked={this.state.checkedA}
            onChange={this.handleChange('checkedA')}
            value='checkedA'
          />
        </div>

        <div className='section-one'>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div>
                <h1 className='header-text'>{strings.your_ordering_channel}</h1>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              {/* <DemoPage /> */}

              <img src='pulse.png' className='pulse-image' alt='' />

              {/* <div className="pulse"></div>
              <div className="pulse2"></div>
              <div className="pulse3"></div>
              <div className="pulse4"></div> */}
            </Grid>
          </Grid>
        </div>
        <div className='section-two'>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <img
                src='icons/commission.png'
                className='feature-icon'
                alt={strings.no_commission}
              />
              {/* <Hoverable/> */}
              <br />
              <span className='feature-title'>{strings.no_commission}</span>
              <br />
              <p className='feature-description'>
                {strings.have_your_regular_customer}
              </p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                src='icons/database.png'
                className='feature-icon'
                alt={strings.keep_customers_data}
              />
              <br />
              <span className='feature-title'>
                {strings.keep_customers_data}
              </span>
              <br />
              <p className='feature-description'>{strings.capture_customer}</p>
              {/* <p className="feature-description">
                Capture customer contact data to sell them again
              </p> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                src='icons/gift.png'
                className='feature-icon'
                alt={strings.loyalty_program}
              />
              <br />
              <span className='feature-title'>{strings.loyalty_program}</span>
              <br />
              <p className='feature-description'>
                {strings.motivate_your_customers}
              </p>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={4}>
              <img
                src='icons/technical-support.png'
                className='feature-icon'
                alt={strings.technical_support}
              />
              <br />
              <span className='feature-title'>{strings.technical_support}</span>
              <br />
              <p className='feature-description'>
                {strings.our_team_is_always_available}
              </p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                src='icons/facebook.png'
                className='feature-icon'
                alt={strings.reach_40_million}
              />
              <br />
              <span className='feature-title'>{strings.reach_40_million}</span>
              <br />
              <p className='feature-description'>{strings.facebook_daily}</p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                src='icons/stopwatch.png'
                className='feature-icon'
                alt={strings.get_started}
              />
              <br />
              <span className='feature-title'>{strings.get_started}</span>
              <br />
              <p className='feature-description'>{strings.create_your_own}</p>
            </Grid>
          </Grid>
        </div>
        <div className='data-counter-section'>
          <Grid container>
            <Grid
              item
              md={4}
              xs={12}
              className='data-counter-Vdivider'
              style={{ padding: '38px' }}
            >
              <div>
                <img
                  className='users-counter-icon'
                  src='./icons/support1.png'
                  alt='user counter'
                />
              </div>
              <div className='messages-counter'>
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp
                    decimals={0}
                    start={0}
                    end={this.state.didViewCountUp ? 9000 : 0}
                    suffix=' +'
                    duration={1.5}
                  />
                </VisibilitySensor>
              </div>
              <p>{strings.orders}</p>
            </Grid>
            {/* <Divider className="data-counter-Hdivider" /> */}
            <Grid item md={4} xs={12} style={{ padding: '38px' }}>
              <div>
                <img className='users-counter-icon' src='./icons/user1.png' />
              </div>
              <div className='users-counter'>
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp
                    decimals={0}
                    start={0}
                    end={this.state.didViewCountUp ? 70000 : 0}
                    suffix=' +'
                    duration={1}
                  />
                </VisibilitySensor>
              </div>
              <p>{strings.users}</p>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              className='data-counter-Vdivider'
              style={{ padding: '38px' }}
            >
              <div>
                <img
                  className='users-counter-icon'
                  src='./icons/placeholder.png'
                />
              </div>
              <div className='messages-counter'>
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp
                    decimals={0}
                    start={0}
                    end={this.state.didViewCountUp ? 300 : 0}
                    suffix=' '
                    duration={1.5}
                  />
                </VisibilitySensor>
              </div>
              <p>{strings.locations}</p>
            </Grid>
          </Grid>
        </div>

        <div className='section-three'>
          <Grid container>
            <Grid sm={6}>
              <div className='phone-text'>{strings.how_orders_are_made}</div>
            </Grid>

            <Grid item sm={6}>
              <div>
                {/* <img src="orders3.gif" className="phone-img" width={450} />{' '} */}
                <img
                  src={image}
                  className='phone-img'
                  width={450}
                  alt='phone'
                />{' '}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='section-four'>
          <Grid container>
            <Grid sm={6}>
              <div>
                <img
                  src='desktop_with_dashboard.png'
                  className='desktop-img'
                  alt='dashboard'
                />{' '}
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className='desktop-text'>
                {strings.how_orders_are_received}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='restaurants-section'>
          <Grid style={{ margin: 'auto' }} lg={8}>
            <div className='slider-title'>{strings.savings_calculator}</div>
            <SimpleSlider />
          </Grid>
        </div>

        <div className='team-section' id='team-section'>
          <div ref={this.myRef} className='title'>
            <p className='team-title'> {strings.elegate_team}</p>
          </div>
          <div className='team-members-one'>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div className='member-container'>
                  <img
                    src='members/1.jpg'
                    className='member-photo'
                    alt={strings.mahmoud_saieed}
                  />
                  <div className='member-name'>{strings.mahmoud_saieed}</div>
                  <div className='member-role'>{strings.founder}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className='member-container'>
                  <img
                    src='members/ahmed.png'
                    className='member-photo'
                    alt={strings.ahmed_Gamal}
                  />
                  <div className='member-name'>{strings.ahmed_Gamal}</div>
                  <div className='member-role'>{strings.co_founder}</div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='team-members-two'>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={1} />
              <Grid item xs={12} sm={2} md={2}>
                <div className='member-container'>
                  <div className='photo-container'>
                    <img
                      src='members/youssef.png'
                      className='member-photo'
                      alt={strings.youssef_nader}
                    />
                  </div>
                  <div className='member-name'>{strings.youssef_nader}</div>
                  <div className='member-role'>{strings.software_engineer}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <div className='member-container'>
                  <img
                    src='members/sarah.png'
                    className='member-photo'
                    alt={strings.sarah_ashraf}
                  />
                  <div className='member-name'>{strings.sarah_ashraf}</div>
                  <div className='member-role'>{strings.ui_ux_designer}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <div className='member-container'>
                  <img
                    src='members/amr-mostafa.jpg'
                    className='member-photo'
                    alt={strings.amr_mostafa}
                  />
                  <div className='member-name'>{strings.amr_mostafa}</div>
                  <div className='member-role'>{strings.software_engineer}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <div className='member-container'>
                  <img
                    src='members/ashraf.png'
                    className='member-photo'
                    alt={strings.mohamed_ashraf}
                  />
                  <div className='member-name'>{strings.mohamed_ashraf}</div>
                  <div className='member-role'>{strings.sales_manager}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <div className='member-container'>
                  <img
                    src='members/abdo.png'
                    className='member-photo'
                    alt={strings.abdelrahman_hassan}
                  />
                  <div className='member-name'>
                    {strings.abdelrahman_hassan}
                  </div>
                  <div className='member-role'>{strings.Web_designer}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={1} />
            </Grid>
          </div>
        </div>
        <div className='whole'>
          <div className='contact-section'>
            <Grid container>
              <Grid item xs={0} sm={2} />
              <Grid item xs={12} sm={3}>
                <div className='follow-us-container'>
                  <div>
                    <h4>{strings.follow_us_on}</h4>
                  </div>
                  <span className='social-buttons'>
                    <IconButton
                      className='btn'
                      href='https://www.facebook.com/elegateapp/'
                      target='_blank'
                    >
                      <img
                        src='facebook-social.png'
                        className='social-icon'
                        alt='facebook'
                      />
                    </IconButton>
                  </span>
                  <span className='social-buttons'>
                    <IconButton
                      className='btn'
                      href='https://www.linkedin.com/company/elegateapp/'
                      target='_blank'
                    >
                      <img
                        src='linkedin-social.png'
                        className='social-icon'
                        alt='linkedin'
                      />
                    </IconButton>
                  </span>
                  <span className='social-buttons'>
                    <IconButton
                      className='btn'
                      href='https://instagram.com/elegateapp'
                      target='_blank'
                    >
                      <img
                        src='instagram-social.png'
                        className='social-icon'
                        alt='instgram'
                      />
                    </IconButton>
                  </span>
                </div>
                <div className='contact-us-container'>
                  <div>
                    <h4>{strings.contact_us}</h4>
                  </div>
                  {/* <div><img src="elegate_logo.png" className="elegate-logo"/></div> */}
                  <div>
                    <div className='contact-row'>
                      <Icon className='icon'>call</Icon> +20 111 499 2002
                    </div>
                    <div className='contact-row'>
                      <Icon className='icon'>email</Icon> ms@elegate.org
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={5}>
                <div className='address-container'>
                  <div>
                    <h4>{strings.our_address}</h4>
                  </div>
                  <p>{strings.the_greek_campus}</p>
                </div>
              </Grid>
              <Grid xs={0} item sm={2} />
            </Grid>
          </div>
        </div>
        <div className='footer' />
      </div>
    );
  }
}

export default windowSize(withStyles(styles)(MainPage));
