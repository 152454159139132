import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import Navbar from './Navbar'
import suggestions from './suggestions'
import Iframe from 'react-iframe'
import { Button } from '@material-ui/core'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 60,
    margin: 'auto',
    marginTop: '0rem',
    paddingTop: '8rem',
    width: '260px'
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
})

function NoOptionsMessage(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}>
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
}

class DemoPage extends React.Component {
  state = {
    single: null,
    view: 0
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
      view: 2
    })
  }

  render() {
    const { classes, theme } = this.props

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    }

    return (
      <React.Fragment>
        {/* <Navbar /> */}
        <div className='mobile-demo'>
          <div className={classes.root}>
            <NoSsr>
              <Select
                classes={classes}
                styles={selectStyles}
                options={suggestions}
                components={components}
                value={this.state.single}
                onChange={this.handleChange('single')}
                placeholder='Search for your restaurant'
                isClearable
              />
              <div className={classes.divider} />
            </NoSsr>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%'
            }}>
            {/* <Button style={{ margin: '1rem' }} variant='outlined'>
            Your Demo Bot
          </Button> */}
            {this.state.single && this.state.view === 1 && (
              <Iframe
                url={`https://pizzeria-la-favole.firebaseapp.com/?restaurant_name=${
                  this.state.single.value
                }&isMenu=true`}
                width='388px'
                height='478px'
                display='initial'
                position='relative'
                allowFullScreen
                className='iframe'
              />
            )}
            {this.state.view === 2 && this.state.single && (
              <Button
                onClick={() => {
                  this.setState({ view: 3 })
                  setTimeout(() => {
                    this.setState({ view: 5 })
                    setTimeout(() => {
                      this.setState({ view: 6 })
                      setTimeout(() => {
                        this.setState({ view: 7 })
                      }, 1500)
                    }, 1500)
                  }, 1500)
                }}
                style={{ marginTop: '400px' }}
                variant='outlined'>
                Get Started
              </Button>
            )}
            {(this.state.view === 5 ||
              this.state.view === 3 ||
              this.state.view === 6 ||
              this.state.view === 7) && (
              <div
                style={{
                  border: '1px solid grey',
                  padding: '5px',
                  alignSelf: 'start',
                  marginLeft: '90px',
                  marginBottom: '1.3rem',
                  borderRadius: '6px'
                }}>{`${this.state.single.label} اهلا يا بك في مطعم`}</div>
            )}
            {(this.state.view === 5 ||
              this.state.view === 6 ||
              this.state.view === 7) && (
              <div
                style={{
                  border: '1px solid grey',
                  padding: '5px',
                  alignSelf: 'start',
                  marginLeft: '90px',
                  marginBottom: '1.3rem',
                  borderRadius: '6px'
                }}>
                انت شكلك جعان
              </div>
            )}
            {(this.state.view === 6 || this.state.view === 7) && (
              <div
                dir='rtl'
                style={{
                  border: '1px solid grey',
                  padding: '5px',
                  alignSelf: 'start',
                  marginLeft: '90px',
                  marginBottom: '1.3rem',
                  width: '65%',
                  borderRadius: '6px'
                }}>
                دي شوية اختصارات:
                <br />
                لو حابب تشوف المنيو دوس علي كلمة "المنيو"
                <br />
                لو حابب تطلب اوردر اونلاين دوس علي "اطلب"
                <br />
                لو حابب تعرف عنوان فروعنا دوس علي "العنوان"
              </div>
            )}
            {this.state.view === 7 && (
              <Button
                variant='outlined'
                onClick={() => this.setState({ view: 1 })}>
                المنيو
              </Button>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

DemoPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(DemoPage)
