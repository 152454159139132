import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import './App.css';
import MainPage from './MainPage';
import PartnerWithUs from './PartnerWithUs';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: 'GTM-T489S2M'
};
TagManager.initialize(tagManagerArgs);

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/' component={MainPage} />
          <Route exact path='/form' component={PartnerWithUs} />
          {/* <Route exact pathe="/ourTeam" component={TeamPage} /> */}
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
