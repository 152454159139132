import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PartnerForm from './PartnerForm';
import NavBar from './Navbar';
import { NavLink } from 'react-router-dom';
import { strings } from './components/languages';

class PartnerWithUs extends React.Component {
  render() {
    return (
      <div>
        <NavBar />
        <PartnerForm />
        <div className='section-one'>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div>
                <h1 className='header-text'>{strings.get_your_ordering}</h1>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default PartnerWithUs;
