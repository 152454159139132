import React, { Component } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import NumberFormat from 'react-number-format';
import './App.css';
import './slider.css';
import { strings } from './components/languages';

class SimpleSlider extends Component {
  state = {
    orderPrice: 100,
    orderCount: 200,
    commission: 20,
    step: 1
  };

  handleOrderPriceChange = value => {
    this.setState({ orderPrice: value });
  };

  handleCommissionChange = value => {
    this.setState({ commission: value });
  };

  handleOrderChange = value => {
    this.setState({ orderCount: value });
  };

  render() {
    const { orderPrice, orderCount, step, commission } = this.state;
    // const commas = this.setState({
    //   orderPrice: orderPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    // });
    const orderPriceLabels = {
      0: 0,
      500: 500
    };
    const orderLabels = {
      10: 10,
      1000: 1000
    };

    const commissionLabels = {
      10: '10%',
      50: '50%',
      100: '100%'
    };

    const dailyOrders = orderCount / 7;

    const total = ((commission / 100) * orderPrice * dailyOrders * 365).toFixed(
      0
    );
    const elegate = (5 * dailyOrders * 365).toFixed(0);
    const mainTotal = (total - elegate).toFixed(0);
    const percentage = (mainTotal / total) * 100;

    return (
      <React.Fragment>
        <section>
          <div className='member-name' style={{ margin: 30 }}>
            {strings.What_is_your_average_order}
          </div>
          <h4 className='member-role' style={{ textAlign: 'center' }}>
            {orderPrice} L.E
          </h4>
          <div className='slider-horizontal'>
            <Slider
              value={orderPrice}
              orientation='horizontal'
              onChange={this.handleOrderPriceChange}
              min={0}
              max={500}
              step={step}
              labels={orderPriceLabels}
              tooltip={false}
            />
          </div>
          <div className='member-name' style={{ margin: 40 }}>
            {strings.how_many_orders}
          </div>
          <h4 className='member-role' style={{ textAlign: 'center' }}>
            {orderCount} {strings.orders_per_Week}
          </h4>
          <Slider
            value={orderCount}
            orientation='horizontal'
            onChange={this.handleOrderChange}
            min={10}
            max={1000}
            step={step}
            labels={orderLabels}
            tooltip={false}
          />
          <div className='member-name' style={{ margin: 40 }}>
            {strings.average_commission}
          </div>
          <h4 className='member-role' style={{ textAlign: 'center' }}>
            {commission} %
          </h4>
          <Slider
            value={commission}
            orientation='horizontal'
            onChange={this.handleCommissionChange}
            min={0}
            max={100}
            step={step}
            labels={commissionLabels}
            tooltip={false}
            style={{ outline: 'none' }}
          />
        </section>
        <br />
        <br />
        <br />
        <div className='slider-header'>
          <span className='danger'>Uber eats / Glovo / Otlob</span> average
          commission is <span className='danger'>{commission} %</span>, costing
          you{' '}
          <span className='danger'>
            <NumberFormat
              value={total}
              displayType={'text'}
              thousandSeparator={true}
            />
          </span>{' '}
          L.E per year.
        </div>
        <br />
        <br />
        <div className='slider-header'>
          <span className='elegate'>Elegate</span> fee per order is{' '}
          <span className='success'>5.00 </span> L.E, costing you{' '}
          <span className='success'>
            <NumberFormat
              value={elegate}
              displayType={'text'}
              thousandSeparator={true}
            />
          </span>{' '}
          L.E per year.{' '}
        </div>
        <br />
        <br />
        <div className='slider-header' style={{ fontSize: 21 }}>
          By Using <span className='elegate'>Elegate</span>, Your Restaurant
          Saves{' '}
          <span style={{ color: '#00CC00', fontSize: 27 }} className='success'>
            <NumberFormat
              value={mainTotal}
              displayType={'text'}
              thousandSeparator={true}
            />
          </span>{' '}
          L.E Per Year which is{' '}
          <span className='success' style={{ color: '#00CC00', fontSize: 27 }}>
            {percentage.toFixed(0)}%
          </span>
          .
        </div>
        <br />
      </React.Fragment>
    );
  }
}

export default SimpleSlider;
