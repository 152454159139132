import React, { Component } from 'react';
import NavDrawer from './Drawer';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { strings } from './components/languages';
import { Button } from '@material-ui/core';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <nav className='navbar'>
        {/* <span onClick={this.toggleHidden.bind(this)} class="navbar-toggle" id="js-navbar-toggle">
            <MenuIcon />
        </span> */}
        <NavDrawer />
        <NavLink to='/' className='navbar-logo'>
          E l e g a t e
        </NavLink>
        <ul className='navbar-list-items'>
          {/* {['Blog', 'Partner with us', 'Our team'].map((text) => (

            <li><a href="#" className="navbar-item">{text}</a></li>
          ))} */}
          <li>
            <NavLink to='/' className='navbar-item'>
              {strings.home}
            </NavLink>
          </li>
          <li>
            <a
              href='https://blog.elegate.org/'
              target='_blank'
              className='navbar-item'
            >
              {strings.blog}
            </a>
          </li>
          {/*<li><NavLink to="/form" className="navbar-item" >Partner with us</NavLink></li> */}
          <li>
            <NavLink to='/form' className='navbar-item'>
              {strings.partner_with_us}
            </NavLink>
          </li>

          {/* <li><a href="#team-section" className="navbar-item">Our team</a></li> */}
        </ul>
      </nav>
    );
  }
}
export default Navbar;
