import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  ar: {
    change_language: 'Change to english',
    home: 'الرئيسية',
    blog: 'المدونة',
    partner_with_us: 'إبدأ العمل معًا',
    your_ordering_channel: 'متجرك الإلكتروني لنجاح مشروعك',
    no_commission: 'بدون عمولة',
    have_your_regular_customer:
      'سوف يطلب عملاؤك من خلال متجرك الإلكتروني بسهولة',
    keep_customers_data: 'إحتفظ بمعلومات عملاءَك',
    capture_customer: 'إعرف عملاءَك جيدًا وقم بالبيع لهم دوما من متجرك',
    loyalty_program: ' برنامج الولاء',
    motivate_your_customers: 'حمس عملاءك للطلب من خلال متجرك الإلكتروني',
    technical_support: 'خدمة العملاء 24 ساعة',
    our_team_is_always_available:
      'فريقنا متواجد دائما لحل اي مشكلة تقنية في متجرك الإلكتروني',
    reach_40_million: 'يحتوي فيسبوك على أربعين مليون مستخدم',
    facebook_daily: 'مستخدمين الفيسبوك في مصر يوميا',
    get_started: 'إبدأ حالًا',
    create_your_own: 'متجرك الإلكتروني في دقائق',
    orders: 'الطلبات',
    users: 'المستخدمين',
    locations: ' الفروع',
    how_orders_are_made: 'كيفية عمل الطلبات',
    how_orders_are_received: 'كيفية إستقبال طلباتك',
    savings_calculator: 'كم ستوفر؟',
    What_is_your_average_order: 'ما هو متوسط سعر الطلب في مشروعك؟',
    currency: 'جنيه',
    how_many_orders: 'كم عدد الطلبات التي يستقبلها مشروعك في الإسبوع؟',
    orders_per_Week: 'طلب في الإسبوع',
    average_commission: 'متوسط العمولة    ',
    uber_eats:
      'اوبر إيتس - أطلب - جلوفو يأخذون عشرون بالمئة منك وسوف يكلفك هذا ',
    elegate_fee: 'سعرنا هو 5 جنيه فقط على الطلب وسوف يكلفك هذا سنويا  ',
    your_restaurant_saves: 'بإستخدام إليجيت مشروعك سيوفر  في خلال السنة',
    elegate_team: 'فريق إليجيت',
    mahmoud_saieed: 'محمود سعيد',
    founder: 'مؤسس ومدير الشركة',
    ahmed_Gamal: 'أحمد جمال',
    co_founder: 'مؤسس ومهندس برمجيات',
    youssef_nader: 'يوسف نادر',
    software_engineer: 'مهندس برمجيات',
    sarah_ashraf: 'سارة أشرف',
    ui_ux_designer: 'مصممة مواقع',
    amr_mostafa: ' عمرو مصطفى',
    mohamed_ashraf: ' محمد أشرف',
    sales_manager: 'مدير المبيعات',
    abdelrahman_hassan: 'عبدالرحمن حسن',
    Web_designer: 'مطور برمجيات',
    follow_us_on: ' تابعنا على',
    contact_us: 'اتصل بنا',
    our_address: 'العنوان',
    the_greek_campus: 'الحرم اليوناني - التحرير  - القاهرة',
    //Partener with us
    get_your_ordering: 'إستلم متجرك الإلكتروني في الحال',
    name: 'الاسم',
    phone: 'الموبايل',
    email: 'البريد الإلكتروني',
    restaurant_name: 'إسم المطعم',
    restaurant_address: 'مكان المطعم',
    do_you_deliver: 'هل لديك خدمة توصيل؟',
    yes: 'نعم',
    Your_current_orders: 'كم هو عدد طلباتك في خلال الإسبوع؟',
    orders_week: 'طلب/الإسبوع',
    lets_chat: 'إبعت',
    sent: 'وصل بنجاح',
    message_sent_successfully: 'وصلنا المعلومات بنجاح- سوف نتصل بك قريبًا'
  },
  en: {
    change_language: 'Change Language to Arabic',
    home: 'Home',
    blog: 'Blog',
    partner_with_us: 'Partner with us',
    your_ordering_channel: 'Your ordering channel for business success',
    no_commission: 'No commission',
    have_your_regular_customer:
      'Have your regular customer order directly from you',
    keep_customers_data: 'Keep customers data',
    capture_customer: 'Capture customer contact data and sell to them again',
    loyalty_program: 'Loyalty Program',
    motivate_your_customers:
      'Motivate your customers to keep ordering using our loyalty/gift system',
    technical_support: '24/7 Technical support',
    our_team_is_always_available:
      'Our team is always available if there are any issues or changes required',
    reach_40_million: 'Reach 40 Million users',
    facebook_daily: 'Facebook daily active users in Egypt',
    get_started: 'Get started in no time',
    create_your_own:
      'Create your own branded channel and launch in hours not months',
    orders: 'Orders',
    users: 'Users',
    locations: 'Locations',
    how_orders_are_made: 'How orders are made',
    how_orders_are_received: 'How orders are received',
    savings_calculator: 'Savings Calculator',
    What_is_your_average_order: 'What is your average order price ?',
    currency: 'L.E',
    how_many_orders: 'How many orders do you receive per week ?',
    orders_per_Week: 'Orders Per Week',
    average_commission: 'Average Commission',
    uber_eats:
      'Uber eats / Glovo / Otlob average commission is 20 %, costing you ',
    elegate_fee: 'Elegate fee per order is 5.00 L.E, costing you',
    your_restaurant_saves: 'By Using Elegate, Your Restaurant Saves',
    elegate_team: 'Elegate Team',
    mahmoud_saieed: 'Mahmoud Saieed',
    founder: 'Founder & CEO',
    ahmed_Gamal: 'Ahmed Gamal',
    co_founder: 'Co-Founder & Software Architect',
    youssef_nader: 'Youssef Nader',
    software_engineer: 'Software Engineer',
    sarah_ashraf: 'Sarah Ashraf',
    ui_ux_designer: 'UI/UX Designer',
    amr_mostafa: 'Amr Mostafa',
    mohamed_ashraf: 'Mohamed Ashraf',
    sales_manager: 'Sales Manager',
    abdelrahman_hassan: 'Abdelrahman Hassan',
    Web_designer: 'Web Designer',
    follow_us_on: 'Follow us on',
    contact_us: 'Contact us',
    our_address: 'Our address',
    the_greek_campus:
      'The Greek Campus, 171 El Tahrir, Ad Dawawin, Abdeen, Cairo Governorate 11513, Cairo Governorate 11556',
    //Partener with us
    get_your_ordering: 'Get your ordering system now.',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    restaurant_name: 'Restaurant Name',
    restaurant_address: 'Restaurant Address',
    do_you_deliver: 'Do you provide a delivery service?',
    yes: 'Yes',
    Your_current_orders: 'Your current number of orders per week?',
    orders_week: 'orders/week',
    lets_chat: "let's chat",
    sent: 'sent',
    message_sent_successfully: 'Message Sent Successfully'
  }
});
